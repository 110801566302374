// import React, { useEffect, useState } from 'react';

// const images = [
//   './assets/public/TORSIONMETER.png',
//   './assets/public/TORSION_METER_INSIDE.png',
//   // Add more paths
// ];

// export default function HomePage() {
//   const [showContent, setShowContent] = useState(false);

//   useEffect(() => {
//     setShowContent(true);
//   }, []);

//   return (
//     <div>
//       <h1>Our Product Line</h1>
//       <div>
//         {images.map((src, index) => (
//           <img key={index} src={src} alt={`Product ${index}`} />
//         ))}
//       </div>
//     </div>
//   );
// }



// import { useState, useEffect } from 'react';

// const images = [
//   '/TORSIONMETER.png',
//   '/TORSION_METER_INSIDE.png',
//   '/TORSIONMETER--BAG.png',
//   '/optical_torsion.png',
//   '/ENGING-TELEGRAPH-SYSTEM-MASTER-.png',
//   '/ENGING-TELEGRAPH-SYSTEM-MASTER-02.png',
//   '/Propeller_Order.jpg',
//   '/lora.png',
// ];

// const HomePage = () => {
//   const [showContent, setShowContent] = useState(false);

//   useEffect(() => {
//     setShowContent(true);
//   }, []);

//   return (
//     <div className="home-page">
//       <h1 className="text-center text-2xl font-bold">Our Product Line</h1>
//       <div className="image-carousel mt-10 overflow-hidden relative">
//         <div className="image-track">
//           {images.map((src, index) => (
//             <div key={index} className="image-card">
//               <img 
//                 src={src} 
//                 alt={`Image ${index + 1}`} 
//                 className="carousel-image"
//               />
//             </div>
//           ))}
//           {images.map((src, index) => (
//             <div key={index + images.length} className="image-card">
//               <img 
//                 src={src} 
//                 alt={`Image ${index + 1}`} 
//                 className="carousel-image"
//               />
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomePage;






import { useState, useEffect } from 'react';

const images = [
  '/TORSIONMETER.png',
  '/TORSION_METER_INSIDE.png',
  '/TORSIONMETER--BAG.png',
  '/optical_torsion.png',
  '/ENGING-TELEGRAPH-SYSTEM-MASTER-.png',
  '/ENGING-TELEGRAPH-SYSTEM-MASTER-02.png',
  '/Propeller_Order.jpg',
  '/lora.png',
];

const HomePage = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    setShowContent(true);
  }, []);

  return (
    <div className="home-page">
      <h1 className="text-center text-2xl font-bold">Our Product Line</h1>
      <div className="image-carousel mt-10">
        <div className="image-track flex">
          {images.map((src, index) => (
            <div key={index} className="image-card">
              <img 
                src={src} 
                alt={`Product ${index + 1}`} 
                className="carousel-image"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;

