// //C:\means\react-pro\src\App.js
// import './App.css';
// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons'; 
// import MeansLogo from './assets/public/Means.png';
// import SeabirdLogo from './assets/public/SEA-BIRD.png';
// import InfraLogo from './assets/public/Means-Infra.png';
// import WaterImage from './assets/public/WaterImage.png';
// import ContactForm from './components/contact_form';
// import ProductCarousel from './components/product';
// import HomePage from './components/HomePage';
// import AboutUs from './components/AboutUs';
// import Services from './components/Services';

// function Home() {
//   const [activeSection, setActiveSection] = useState('home');
//   const [menuOpen, setMenuOpen] = useState(false);

//   return (
//     <div
//       className="min-h-screen bg-cover bg-center bg-no-repeat"
//       style={{ backgroundImage: 'url(./assets/public/ocean-background.jpg)' }}
//     >
//       {/* Header with Logos */}
//       <header className="flex flex-col justify-between items-center bg-white bg-opacity-50 py-6 px-70">
//         <div className="flex justify-evenly items-center w-full mb-1">
//           <img src={InfraLogo} alt="Means Infrastructure Logo" width={140} height={90} />
//           <img src={MeansLogo} alt="Means Logo" width={210} height={130} />
//           <img src={SeabirdLogo} alt="Seabird Surveys Logo" width={110} height={55} />
//         </div>
//       </header>

//       {/* Water Image */}
//       <div className="relative w-full h-[250px] overflow-hidden mt-0.1 z-20">
//         <img src={WaterImage} alt="Curved Water Image" className="w-full h-full object-cover object-top" />
//       </div>

//       {/* Navigation Tabs */}
//       <nav className="flex justify-center text-gray-700 relative sticky top-0 z-50">
//         <div className="wave-bg absolute bottom-0 w-full h-16 bg-blue-200"></div>
//         <div className="relative w-full h-16 hidden lg:flex">
//           <div className="flex justify-center w-full gap-12 pr-10 pl-4 text-black text-lg">
//             {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//               <button
//                 key={section}
//                 onClick={() => setActiveSection(section)}
//                 className={`py-4 px-5 transition relative z-10 ${
//                   activeSection === section ? 'active-tab' : ''
//                 }`}
//               >
//                 {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//               </button>
//             ))}
//           </div>
//         </div>

//         {/* Mobile Menu Button */}
//         <div className="lg:hidden flex items-center justify-center w-full absolute inset-x-0 bottom-[-50px]">
//           <button className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
//             <FontAwesomeIcon icon={faBars} className="text-black text-3xl" />
//           </button>
//         </div>

//         {/* Sidebar Menu for Mobile */}
//         {menuOpen && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
//             <div className="fixed left-0 top-0 w-64 h-full bg-white shadow-lg z-50">
//               <button className="p-4 text-right w-full" onClick={() => setMenuOpen(false)}>
//                 <FontAwesomeIcon icon={faBars} className="text-black text-2xl" />
//               </button>
//               <div className="flex flex-col items-start px-6 py-4 space-y-4">
//                 {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//                   <button
//                     key={section}
//                     onClick={() => {
//                       setActiveSection(section);
//                       setMenuOpen(false);
//                     }}
//                     className="menu-item"
//                   >
//                     {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </nav>

//       {/* Sections */}
//       <section className={`py-16 px-10 ${activeSection === 'home' ? '' : 'hidden'}`}>
//         <HomePage />
//       </section>

//       <section
//         id="about"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'about' ? '' : 'hidden'}`}
//       >
//         <h1 className="text-3xl font-bold mb-6 text-black text-center">About us</h1>
//         <AboutUs />
//       </section>

//       <section id="products" className={`py-16 px-10 z-1 ${activeSection === 'products' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">Our Products</h1>
//         <ProductCarousel />
//       </section>

//       <section
//         id="services"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'services' ? '' : 'hidden'}`}
//       >
//         <Services />
//       </section>

//       <section id="news" className={`py-16 px-10 ${activeSection === 'news' ? '' : 'hidden'}`}>
//         <p className="text-lg text-black-200 max-w-4xl mx-auto text-center">
//           Stay tuned for the latest updates and news from Marine Electronics And Navigation Systems Private
//           Limited (MEANS)...
//         </p>
//       </section>

//       {/* Contact Us Section */}
//       {activeSection === 'contact' && <ContactForm />}

//       <section id="hsn" className={`py-16 px-10 ${activeSection === 'hsn' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">HSN</h1>
//         <p className="text-lg text-gray-200 max-w-4xl mx-auto text-center">HSN details go here.</p>
//       </section>
//     </div>
//   );
// }


// function App() {
//   return (
//     <div className="App">
// <Home/>
//     </div>
//   );
// }

// export default Home;






// import './App.css';
// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons';

// import MeansLogo from './Means.png';
// import SeabirdLogo from './public/SEA-BIRD.png';
// import InfraLogo from './public/Means-Infra.png';
// import WaterImage from './public/WaterImage.png';

// import ContactForm from './components/contact_form';
// import ProductCarousel from './components/product';
// import HomePage from './components/HomePage';
// import AboutUs from './components/AboutUs';
// import Services from './components/Services';

// function Home() {
//   const [activeSection, setActiveSection] = useState('home');
//   const [menuOpen, setMenuOpen] = useState(false);

//   return (
//     <div
//       className="min-h-screen w-screen bg-cover bg-center bg-no-repeat"
//       style={{ backgroundImage: 'url(./assets/public/ocean-background.jpg)' }}
//     >
//       {/* Header with Logos */}
//       <header className="flex flex-col justify-between items-center bg-white bg-opacity-50 py-6 px-10">
//         <div className="flex justify-evenly items-center w-full mb-1">
//           <img src={InfraLogo} alt="Means Infrastructure Logo" width={140} height={90} />
//           <img src={MeansLogo} alt="Means Logo" width={210} height={130} />
//           <img src={SeabirdLogo} alt="Seabird Surveys Logo" width={110} height={55} />
//         </div>
//       </header>

//       {/* Water Image */}
//       <div className="relative w-screen h-[250px] overflow-hidden mt-0.1 z-20">
//         <img src={WaterImage} alt="Curved Water Image" className="w-screen h-full object-cover object-top" />
//       </div>

//       {/* Navigation Tabs */}
//       <nav className="flex justify-center w-screen text-gray-700 relative sticky top-0 z-50">
//         <div className="wave-bg absolute bottom-0 w-full h-16 bg-blue-200"></div>
//         <div className="relative w-full h-16 hidden lg:flex">
//           <div className="flex justify-center w-full gap-12 pr-10 pl-4 text-black text-lg">
//             {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//               <button
//                 key={section}
//                 onClick={() => setActiveSection(section)}
//                 className={`py-4 px-5 transition relative z-10 ${
//                   activeSection === section ? 'active-tab' : ''
//                 }`}
//               >
//                 {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//               </button>
//             ))}
//           </div>
//         </div>

//         {/* Mobile Menu Button */}
//         <div className="lg:hidden flex items-center justify-center w-full absolute inset-x-0 bottom-[-50px]">
//           <button className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
//             <FontAwesomeIcon icon={faBars} className="text-black text-3xl" />
//           </button>
//         </div>

//         {/* Sidebar Menu for Mobile */}
//         {menuOpen && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
//             <div className="fixed left-0 top-0 w-64 h-full bg-white shadow-lg z-50">
//               <button className="p-4 text-right w-full" onClick={() => setMenuOpen(false)}>
//                 <FontAwesomeIcon icon={faBars} className="text-black text-2xl" />
//               </button>
//               <div className="flex flex-col items-start px-6 py-4 space-y-4">
//                 {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//                   <button
//                     key={section}
//                     onClick={() => {
//                       setActiveSection(section);
//                       setMenuOpen(false);
//                     }}
//                     className="menu-item"
//                   >
//                     {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </nav>

//       {/* Sections */}
//       <section className={`py-16 px-10 ${activeSection === 'home' ? '' : 'hidden'}`}>
//         <HomePage />
//       </section>

//       <section
//         id="about"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'about' ? '' : 'hidden'}`}
//       >
//         <h1 className="text-3xl font-bold mb-6 text-black text-center">About us</h1>
//         <AboutUs />
//       </section>

//       <section id="products" className={`py-16 px-10 z-1 ${activeSection === 'products' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">Our Products</h1>
//         <ProductCarousel />
//       </section>

//       <section
//         id="services"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'services' ? '' : 'hidden'}`}
//       >
//         <Services />
//       </section>

//       <section id="news" className={`py-16 px-10 ${activeSection === 'news' ? '' : 'hidden'}`}>
//         <p className="text-lg text-black-200 max-w-4xl mx-auto text-center">
//           Stay tuned for the latest updates and news from Marine Electronics And Navigation Systems Private
//           Limited (MEANS)...
//         </p>
//       </section>

//       {/* Contact Us Section */}
//       {activeSection === 'contact' && <ContactForm />}

//       <section id="hsn" className={`py-16 px-10 ${activeSection === 'hsn' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">HSN</h1>
//         <p className="text-lg text-gray-200 max-w-4xl mx-auto text-center">HSN details go here.</p>
//       </section>
//     </div>
//   );
// }

// export default Home;





// import './App.css';
// import React, { useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faBars } from '@fortawesome/free-solid-svg-icons';

// import ContactForm from './components/contact_form';
// import ProductCarousel from './components/product';
// import HomePage from './components/HomePage';
// import AboutUs from './components/AboutUs';
// import Services from './components/Services';

// function Home() {
//   const [activeSection, setActiveSection] = useState('home');
//   const [menuOpen, setMenuOpen] = useState(false);

//   return (
//     <div
//       className="min-h-screen w-screen bg-cover bg-center bg-no-repeat"
//       style={{ backgroundImage: 'url(/ocean-background.jpg)' }} // Correct path
//     >
//       {/* Header with Logos */}
//       <header className="flex flex-col justify-between items-center bg-white bg-opacity-50 py-6 px-10">
//         <div className="flex justify-evenly items-center w-full mb-1">
//           <img src="/Means-Infra.png" alt="Means Infrastructure Logo" width={140} height={90} />
//           <img src="/Means.png" alt="Means Logo" width={210} height={130} />
//           <img src="/SEA-BIRD.png" alt="Seabird Surveys Logo" width={110} height={55} />
//         </div>
//       </header>

//       {/* Water Image */}
//       <div className="relative w-screen h-[250px] overflow-hidden mt-0.1 z-20">
//         <img src="/WaterImage.png" alt="Curved Water Image" className="w-screen h-full object-cover object-top" />
//       </div>

//       {/* Navigation Tabs */}
//       <nav className="flex justify-center w-screen text-gray-700 relative sticky top-0 z-50">
//         <div className="wave-bg absolute bottom-0 w-full h-16 bg-blue-200"></div>
//         <div className="relative w-full h-16 hidden lg:flex">
//           <div className="flex justify-center w-full gap-12 pr-10 pl-4 text-black text-lg">
//             {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//               <button
//                 key={section}
//                 onClick={() => setActiveSection(section)}
//                 className={`py-4 px-5 transition relative z-10 ${
//                   activeSection === section ? 'active-tab' : ''
//                 }`}
//               >
//                 {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//               </button>
//             ))}
//           </div>
//         </div>

//         {/* Mobile Menu Button */}
//         <div className="lg:hidden flex items-center justify-center w-full absolute inset-x-0 bottom-[-50px]">
//           <button className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
//             <FontAwesomeIcon icon={faBars} className="text-black text-3xl" />
//           </button>
//         </div>

//         {/* Sidebar Menu for Mobile */}
//         {menuOpen && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
//             <div className="fixed left-0 top-0 w-64 h-full bg-white shadow-lg z-50">
//               <button className="p-4 text-right w-full" onClick={() => setMenuOpen(false)}>
//                 <FontAwesomeIcon icon={faBars} className="text-black text-2xl" />
//               </button>
//               <div className="flex flex-col items-start px-6 py-4 space-y-4">
//                 {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
//                   <button
//                     key={section}
//                     onClick={() => {
//                       setActiveSection(section);
//                       setMenuOpen(false);
//                     }}
//                     className="menu-item"
//                   >
//                     {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </nav>

//       {/* Sections */}
//       <section className={`py-16 px-10 ${activeSection === 'home' ? '' : 'hidden'}`}>
//         <HomePage />
//       </section>

//       <section
//         id="about"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'about' ? '' : 'hidden'}`}
//       >
//         <h1 className="text-3xl font-bold mb-6 text-black text-center">About us</h1>
//         <AboutUs />
//       </section>

//       <section id="products" className={`py-16 px-10 z-1 ${activeSection === 'products' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">Our Products</h1>
//         <ProductCarousel />
//       </section>

//       <section
//         id="services"
//         className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'services' ? '' : 'hidden'}`}
//       >
//         <Services />
//       </section>

//       <section id="news" className={`py-16 px-10 ${activeSection === 'news' ? '' : 'hidden'}`}>
//         <p className="text-lg text-black-200 max-w-4xl mx-auto text-center">
//           Stay tuned for the latest updates and news from Marine Electronics And Navigation Systems Private
//           Limited (MEANS)...
//         </p>
//       </section>

//       {/* Contact Us Section */}
//       {activeSection === 'contact' && <ContactForm />}

//       <section id="hsn" className={`py-16 px-10 ${activeSection === 'hsn' ? '' : 'hidden'}`}>
//         <h1 className="text-3xl font-bold mb-6 text-white text-center">HSN</h1>
//         <p className="text-lg text-gray-200 max-w-4xl mx-auto text-center">HSN details go here.</p>
//       </section>
//     </div>
//   );
// }

// export default Home;



import './App.css';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import ContactForm from './components/contact_form';
import ProductCarousel from './components/product';
import HomePage from './components/HomePage';
import AboutUs from './components/AboutUs';
import Services from './components/Services';

function Home() {
  const [activeSection, setActiveSection] = useState('home');
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <div
      className="min-h-screen w-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: 'url(/ocean-background.jpg)' }} // Correct path
    >
      {/* Header with Logos */}
      <header className="flex flex-col justify-between items-center bg-white bg-opacity-50 py-6 px-10">
        <div className="flex justify-evenly items-center w-full mb-1">
          <img src="/Means-Infra.png" alt="Means Infrastructure Logo" width={140} height={90} />
          <img src="/Means.png" alt="Means Logo" width={210} height={130} />
          <img src="/SEA-BIRD.png" alt="Seabird Surveys Logo" width={110} height={55} />
        </div>
      </header>

      {/* Water Image */}
      <div className="relative w-screen h-[250px] overflow-hidden mt-0.1 z-20">
        <img src="/WaterImage.png" alt="Curved Water Image" className="w-screen h-full object-cover object-top" />
      </div>

      {/* Navigation Tabs */}
      <nav className="flex justify-center w-screen text-gray-700 relative sticky top-0 z-50">
        <div className="wave-bg absolute bottom-0 w-full h-16 bg-blue-200"></div>
        <div className="relative w-full h-16 hidden lg:flex">
          <div className="flex justify-center w-full gap-12 pr-10 pl-4 text-black text-lg">
            {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
              <button
                key={section}
                onClick={() => setActiveSection(section)}
                className={`py-4 px-5 transition relative z-10 ${
                  activeSection === section ? 'active-tab' : ''
                }`}
              >
                {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Mobile Menu Button */}
        <div className="lg:hidden flex items-center justify-center w-full absolute inset-x-0 bottom-[-50px]">
          <button className="menu-button" onClick={() => setMenuOpen(!menuOpen)}>
            <FontAwesomeIcon icon={faBars} className="text-black text-3xl" />
          </button>
        </div>

        {/* Sidebar Menu for Mobile */}
        {menuOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-40">
            <div className="fixed left-0 top-0 w-64 h-full bg-white shadow-lg z-50">
              <button className="p-4 text-right w-full" onClick={() => setMenuOpen(false)}>
                <FontAwesomeIcon icon={faBars} className="text-black text-2xl" />
              </button>
              <div className="flex flex-col items-start px-6 py-4 space-y-4">
                {['home', 'about', 'products', 'services', 'news', 'contact', 'hsn'].map((section) => (
                  <button
                    key={section}
                    onClick={() => {
                      setActiveSection(section);
                      setMenuOpen(false);
                    }}
                    className="menu-item"
                  >
                    {section === 'about' ? 'About us' : section.charAt(0).toUpperCase() + section.slice(1)}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}
      </nav>

      {/* Sections */}
      <section className={`py-16 px-10 ${activeSection === 'home' ? '' : 'hidden'}`}>
        <HomePage />
      </section>

      <section
        id="about"
        className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'about' ? '' : 'hidden'}`}
      >
        <h1 className="text-3xl font-bold mb-6 text-black text-center">About us</h1>
        <AboutUs />
      </section>

      <section id="products" className={`py-16 px-10 z-1 ${activeSection === 'products' ? '' : 'hidden'}`}>
        <h1 className="text-3xl font-bold mb-6 text-white text-center">Our Products</h1>
        <ProductCarousel />
      </section>

      <section
        id="services"
        className={`py-16 px-10 bg-white-900 bg-opacity-75 ${activeSection === 'services' ? '' : 'hidden'}`}
      >
        <Services />
      </section>

      <section id="news" className={`py-16 px-10 ${activeSection === 'news' ? '' : 'hidden'}`}>
        <p className="text-lg text-black-200 max-w-4xl mx-auto text-center">
          Stay tuned for the latest updates and news from Marine Electronics And Navigation Systems Private
          Limited (MEANS)...
        </p>
      </section>

      {/* Contact Us Section */}
      {activeSection === 'contact' && <ContactForm />}

      <section id="hsn" className={`py-16 px-10 ${activeSection === 'hsn' ? '' : 'hidden'}`}>
        <h1 className="text-3xl font-bold mb-6 text-white text-center">HSN</h1>
        <p className="text-lg text-gray-200 max-w-4xl mx-auto text-center">HSN details go here.</p>
      </section>
    </div>
  );
}

export default Home;
