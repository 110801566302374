

import React, { useState, useEffect } from 'react';

const products = [
  {
    images: [
      '/TORSIONMETER.png',
      '/TORSION_METER_INSIDE.png',
      '/TORSIONMETER--BAG.png',
    ],
    alt: 'Torsionmeter',
  },
  {
    images: ['/optical_torsion.png'],
    alt: 'Optical Torsionmeter',
  },
  {
    images: [
      '/ENGING-TELEGRAPH-SYSTEM-MASTER-.png',
      '/ENGING-TELEGRAPH-SYSTEM-MASTER-02.png',
    ],
    alt: 'Engine Order Telegraph System',
  },
  {
    images: [' /Propeller_Order.jpg'],
    alt: 'Propeller Order Telegraph System',
  },
  {
    images: [' /lora.png'],
    alt: 'LoRa Communication system',
  },
];

export default function ProductList() {
  const [currentIndexes, setCurrentIndexes] = useState(
    Array(products.length).fill(0)
  );

  useEffect(() => {
    const handleInitialAnimation = () => {
      const elements = document.querySelectorAll('.product-card');
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          el.classList.add('animate-slide-in');
          el.style.opacity = 1; // Ensure opacity is set to 1 on initial render
        }
      });
    };

    handleInitialAnimation();

    const handleScrollAnimation = () => {
      const elements = document.querySelectorAll('.product-card');
      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          el.classList.add('animate-slide-in');
        } else {
          el.classList.remove('animate-slide-in');
        }
      });
    };

    window.addEventListener('scroll', handleScrollAnimation);

    const imageIntervals = products.map((_, i) =>
      setInterval(() => {
        setCurrentIndexes((prevIndexes) => {
          const newIndexes = [...prevIndexes];
          newIndexes[i] = (newIndexes[i] + 1) % products[i].images.length;
          return newIndexes;
        });
      }, 2000) // Change image every 2 seconds
    );

    return () => {
      window.removeEventListener('scroll', handleScrollAnimation);
      imageIntervals.forEach(clearInterval);
    };
  }, []);

  return (
    <div className="max-w-4xl mx-auto py-8 space-y-8 relative">
      {products.map((product, index) => (
        <div
          key={index}
          className={`product-card flex flex-col lg:flex-row items-center justify-between p-6 bg-white shadow-lg rounded-lg space-y-4 lg:space-y-0 opacity-0 transition-opacity duration-700 ${
            index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
          }`}
          style={{ opacity: 0 }}
        >
          <div className="relative" style={{ width: '300px', height: '300px' }}>
            <img
              src={product.images[currentIndexes[index]]}
              alt={product.alt}
              className="rounded-lg transition-transform duration-700 object-contain w-full h-full"
            />
          </div>
          <div className="w-full text-center lg:text-left lg:w-1/2">
            <h2 className="text-2xl font-bold">{product.alt}</h2>
          </div>
        </div>
      ))}

      {/* Fixed "Make in India" Image at the bottom left */}
      <div className="fixed bottom-0 left-0 p-4">
        <img
          src="/makeinindiajpeg.jpeg" // Corrected path to the "Make in India" logo
          alt="Make in India"
          style={{ width: '150px', height: '150px' }}
        />
      </div>
    </div>
  );
}
